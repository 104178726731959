import { initializeApp} from "firebase/app";
import {
    getAuth,
    signInWithCustomToken,
} from "firebase/auth";
import {postLogin} from "./rest";
import {setPersistence, browserSessionPersistence} from "firebase/auth";
import {getCookie} from "./MyCookies";

const firebaseConfigGSCApp = {
    apiKey: "AIzaSyAkR3RLloWP5UaQ4oE8Qg-u7qkDh6Yhj0I",

    authDomain: "gsc-messaging.firebaseapp.com",

    databaseURL: "https://gsc-messaging.firebaseio.com",

    projectId: "gsc-messaging",

    storageBucket: "gsc-messaging.appspot.com",

    messagingSenderId: "1040463536624",

    appId: "1:1040463536624:web:943414d53bd9f1a9eefd35",

    measurementId: "G-V3H8KZ383G"
}

let auth = null;

const setAuth = () => {
    if (getCookie() === 'gsc-app') {
        const app = initializeApp(firebaseConfigGSCApp);
        auth = getAuth(app);
    }
}

const initFirebase = async (key, location)=>{
    switch (location){
        case "gsc-app":
            let response = await postLogin(key, location)
            if(response.success){
                await setPersistence(auth, browserSessionPersistence);
                try{
                    await signInWithCustomToken(auth, response.response)
                }catch (e){
                    console.log(e)
                }
            }
            break
    }
}

const authToken = async () =>{
    return await auth.currentUser.getIdToken();
}

export {
    auth,
    authToken,
    initFirebase,
    setAuth
}


