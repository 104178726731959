import {auth, authToken, initFirebase, setAuth} from "./firebase";
import React, {useEffect, useRef, useState} from "react";
import {Form, useLocation, useNavigate, useParams} from "react-router-dom";
import {doGet, doGetNoAuth, doPostBody, loadCalendars, loadPublicCalendars, postNoLogin, uploadImage} from "./rest";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import {Client} from "@stomp/stompjs";
import "./calendar.css";
import { ColorRing } from 'react-loader-spinner'
import PopUp from "./PopUp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import rrulePlugin from '@fullcalendar/rrule'
import {
    faCalendar,
    faCalendarAlt,
    faCalendarDays,
    faListNumeric, faMap,
    faNoteSticky,
    faStickyNote, faToggleOff, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {slide as Menu} from "react-burger-menu";
import './Sidebar.css'
import MySpinner from "./Loading";
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {Editor} from "@tinymce/tinymce-react";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import adaptivePlugin from '@fullcalendar/adaptive'
import moment from "moment/moment";

function Calendar() {

    let {calendar } = useParams();

    let { state } = useLocation();

    const navigate = useNavigate()

  //  const [user, loading] = useAuthState(auth);
    const [message, setMessage] = useState();
    const [events, setEvents] = useState([])
    const [view, setView] = useState("dayGridMonth")
    const [calendars, setCalendars] = useState([])
    const [showCalendars, setShowCalendars] = useState([])
    const [allCalendars, setAllCalendars] = useState([]);
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [myEditableCalendars, setMyEditableCalendars] = useState(null);
    const [newEvent, setNewEvent] = useState({})
    const [rrule, setRrule] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [currentStartDate, setCurrentStartDate] = useState();
    const [currentEndDate, setCurrentEndDate] = useState()
    const [allDayEvent, setAllDayEvent] = useState(false)
    const [isRecurrance, setIsRecurrence] = useState(false)
    const [byWeekday, setByWeekDay] = useState([])
    const [publicCalendar, setPublicCalendar] = useState(false)
    const editorRef = useRef(null)
    const calendarRef = React.createRef()
    const [isEditing, setIsEditing] = useState(false)
    const [title, setTitle] = useState("")
    const animatedComponents = makeAnimated();
    const [calendarDisplayString, setCalendarDisplayString] = useState("")
    const loading = useRef(false);
    const firstRun = useRef(true);
    const options = [
        {
            label: "Mon",
            value: "mo"
        }, {
            label: "Tue",
            value: "tu"
        }, {
            label: "Wed",
            value: "we"
        }, {
            label: "Thu",
            value: "th"
        }, {
            label: "Fri",
            value: "fr"
        }, {
            label: "Sat",
            value: "sa"
        },{
            label: "Sun",
            value: "su"
        }
    ];
    const location = useLocation()
    const [calendarSelect, setCalendarSelect] = useState([])
    const [selectedCalendars, setSelectedCalendars] = useState([])
    const [currentEventCalendars, setCurrentEventCalendars] = useState([])
    const [currentSelectedCalendars, setCurrentSelectedCalendars] = useState([])
    const [currentEventEditId, setCurrentEventEditId] = useState()
    const [eventDataForIcal, setEventDateForIcal] = useState(null)

    useEffect(() => {
        if(location.pathname.startsWith( '/calendar/public')){
            setPublicCalendar(true)
            setIsLoading(false)
            setMyEditableCalendars([])
            return
        }
        setAuth()
        auth.onAuthStateChanged(user =>{
            if(user != null){
                if(myEditableCalendars == null){
                    loadMyLocations().then((result) =>{
                        setMyEditableCalendars(...[result]);
                        const list = []
                        for(const i in result){
                            list.push({'value': result[i].id, 'label': result[i].calendarName})
                        }
                        setCalendarSelect(list)
                    })
                }
                startSocket()
                setIsLoading(false)
            }else{
                if(state === null){
                    setMessage("This app can only be though the Garden Spot Communities app.")
                    setIsLoading(false)
                }else{
                    initFirebase(state.key, state.location)
                }

            }
        })
    }, []);

    const togglePopup = () =>{
        if(!popUpOpen){
            document.getElementById("react-burger-cross-btn").click()
        }
        setCurrentSelectedCalendars([])
        setIsEditing(false)
        setPopUpOpen(!popUpOpen);
        setRrule({})
        setByWeekDay([])
        setAllDayEvent(false)
        setIsRecurrence(false)
        setNewEvent({})
    }

    useEffect(()=>{

        if(popUpOpen && currentEventEditId !== null){

            setSelectedCalendarsForUpdate()
        }else{
            setCurrentEventEditId(null)
            setCurrentSelectedCalendars([])
        }
    }, [popUpOpen, currentEventEditId])

    useEffect(() =>{
        let calanderString = "Calendar(s): "
        for(const i in currentEventCalendars){
            calanderString += currentEventCalendars[i].calendarName+", "
        }
        calanderString = calanderString.substring(0, calanderString.length -2)
        setCalendarDisplayString(calanderString)
    }, [currentSelectedCalendars])

    const loadMyLocations = async () =>{
        return await doGet("calendar-controller/my-calendars")
    }

    const onCheckBoxChange = event => {
        let calendarChange = showCalendars;
        let index = calendarChange.indexOf(event.target.value)
        if (index !== -1) {
            calendarChange.splice(index, 1)
        }else{
            calendarChange.push(event.target.value)
        }
        setShowCalendars(calendarChange)
        resetCalenderEvents()
    }

    const onCalendarSelectChange = (val) =>{

        setSelectedCalendars([])
        for(const i in val){
            setSelectedCalendars(selectedCalendars =>[...selectedCalendars, val[i].value])
        }
        setCurrentSelectedCalendars(val)
    }

    useEffect(() =>{
        let result = allCalendars
        let loadedEvents = []
        for(let i = 0 ; i < result.length; i++){
            if(showCalendars.includes(result[i].calendarName)){
                loadedEvents.push(...result[i].calendarEvents)
            }
        }
        setEvents(loadedEvents)
    }, [showCalendars])

    const resetCalenderEvents = () =>{
        let result = allCalendars
        let loadedEvents = []
        for(let i = 0 ; i < result.length; i++){
            if(showCalendars.includes(result[i].calendarName)){
                loadedEvents.push(...result[i].calendarEvents)
            }
        }
        setEvents(loadedEvents)
    }



    const submitForm = async event =>{
        event.preventDefault()
        let cols = document.getElementsByClassName('form-error')
        for(let i = 0; i < cols.length; i++) {
            cols[i].style.display = 'none'
        }

        let success = true
        if(selectedCalendars.length === 0){
            document.getElementById("calendar-error").style.display = 'block'
            success = false
        }
        if(newEvent.title === undefined || newEvent.title === ''){
            document.getElementById("title-error").style.display = 'block'
            success = false
        }
        if(editorRef?.current !== null){
            newEvent.description = editorRef.current.getContent();
        }

        if(isRecurrance){

            if(rrule.interval === undefined){
                rrule.interval = 1
            }
            if(rrule.dtstart === undefined){
                document.getElementById("rrule-start-error").style.display = 'block'
                success = false
            }

            if((newEvent.allDay === undefined || !newEvent.allDay) && newEvent.end === undefined){
                document.getElementById("rrule-end-error").style.display = 'block'
                success = false
            }

            if(isNaN(rrule.interval) || rrule.interval <= 1){
                if((newEvent.allDay === undefined || !newEvent.allDay) && newEvent.end === undefined){
                    document.getElementById("rrule-interval-error").style.display = 'block'
                    success = false
                }
            }

            if(rrule.freq === 'weekly' && byWeekday.length < 1){
                document.getElementById("rrule-weekly-error").style.display = 'block'
                success = false
            }

            if(success){
                for(let i = 0; i < myEditableCalendars.length; i++){
                    if(parseInt(newEvent.calendar) === myEditableCalendars[i].id){
                        newEvent.calendarInt = myEditableCalendars[i].id;
                    }
                }

                if(!newEvent.allDay){
                    const diff = new Date(rrule.dtstart).getTime() -  new Date(newEvent.end).getTime()
                    const diff_as_date = new Date(diff);
                    newEvent.duration = diff_as_date.getHours()+":"+diff_as_date.getMinutes()
                }else{
                        let myDate = new Date(rrule.dtstart)
                        myDate.setUTCHours(0,0,0,0)
                        rrule.dtstart = myDate.toISOString()
                        newEvent.start = myDate.toISOString()
                        myDate.setUTCHours(23,59,59)
                        newEvent.end = myDate.toISOString()

                }
                rrule.byweekday = byWeekday

                newEvent.rrule = rrule

                newEvent.calendars = selectedCalendars;

                console.log(newEvent)

                let resp = await doPostBody(newEvent, "calendar-controller/save-event")
                if(resp.toString().trim().toLowerCase() === "true"){
                    window.alert("Your event has been added.")
                    togglePopup()
                    updateAllCalendars(currentStartDate, currentEndDate)
                }else{
                    window.alert("Something has gone wrong, we could not add your event.")
                }

            }


        }else{

            if((newEvent.allDay === undefined || !newEvent.allDay) && newEvent.start === undefined){
                document.getElementById("start-error").style.display = 'block'
                success = false
            }

            if((newEvent.allDay === undefined || !newEvent.allDay) && newEvent.end === undefined){
                document.getElementById("end-error").style.display = 'block'
                success = false
            }

            if(newEvent.allDay && newEvent.start === undefined){
                document.getElementById("start-error").style.display = 'block'
                success = false
            }

            if(newEvent.allDay){
                let myDate = new Date(newEvent.start)
                myDate.setUTCHours(0,0,0,0)
                newEvent.start = myDate.toISOString()
                myDate.setUTCHours(23,59,59)
                newEvent.end = myDate.toISOString()
            }

            if(success){
                for(let i = 0; i < myEditableCalendars.length; i++){
                    if(parseInt(newEvent.calendar) === myEditableCalendars[i].id){
                        newEvent.calendarInt = myEditableCalendars[i].id;
                    }
                }
                newEvent.calendars = selectedCalendars;
                let resp = await doPostBody(newEvent, "calendar-controller/save-event")
                if(resp.toString().trim().toLowerCase() === "true"){
                    window.alert("Your event has been added.")
                    togglePopup()
                    updateAllCalendars(currentStartDate, currentEndDate)
                }else{
                    window.alert("Something has gone wrong, we could not add your event.")
                }
            }
        }

    }

    const handleChange = event =>{
        setNewEvent(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const formatDate = (date) => {
        return moment(date).format("hh:mm a")
    }

    const handleChangeRrule = event =>{
        setRrule(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const allDayEventChange = event =>{
        setAllDayEvent(!allDayEvent)
        setNewEvent(prevState =>({
            ...prevState,
            [event.target.name]: allDayEvent
        }))
    }

    const recurranceChanges = event =>{
        setIsRecurrence(!isRecurrance)
    }



    const showEvent = async (event) =>{

        event.jsEvent.preventDefault()
        setEventDateForIcal(event.event)
        const resp = await doGetNoAuth("calendar-controller/event/"+event.event.id)
        const resp2 = await doGetNoAuth("calendar-controller/event/calendars/"+event.event.id)
        console.log(resp2)
        if(resp.allDay){
            resp.start = resp.start.split("T")[0]
        }
        setNewEvent(resp)
        if(resp.rrule !== null){
            setRrule(resp.rrule)
            setByWeekDay(resp.rrule.byweekday)
            setIsRecurrence(true)
        }

        setCurrentEventCalendars(resp2)
        setCurrentEventEditId(event.event.id)
        setPopUpOpen(!popUpOpen);

    }

    const setSelectedCalendarsForUpdate = () =>{
        const myNewArray = []
        const selected = []
        for(const j in currentEventCalendars){
            for(let i = 0; i < myEditableCalendars.length; i ++ ){
                if(myEditableCalendars[i].id === currentEventCalendars[j].id){
                    myNewArray.push({'value':currentEventCalendars[j].id, 'label': currentEventCalendars[j].calendarName })
                    selected.push(currentEventCalendars[j].id)
                }
            }
        }
        setSelectedCalendars(selected)
        setCurrentSelectedCalendars(myNewArray)
    }

    const canEditCalendar = (currentCalendar) =>{
        if(currentCalendar === undefined){
            return true
        }
        let canEdit = false;
        for(const j in currentEventCalendars){
            for(let i = 0; i < myEditableCalendars.length; i ++ ){
                if(myEditableCalendars[i].id === currentEventCalendars[j].id){
                    canEdit =  true;
                }
            }
        }
        return canEdit;
    }

    const getCalendarName = (id) =>{
        for(let i = 0; i < calendars.length; i++){
            if(id === calendars[i].id){
                return calendars[i].calendarName
            }
        }
        return ""
    }

    const deleteEvent = async (id) =>{
        if(window.confirm("Are you sure you want to delete this event?")){
            const response = await doGet("calendar-controller/delete-event/"+id)
            if(response.toString().toLowerCase().trim() === 'true'){
                updateAllCalendars(currentStartDate, currentEndDate)
                document.getElementsByClassName("close-icon")[0].click()
            }else{
                window.alert("something has gone wrong the event could not be deleted.")
            }
        }
    }
    function handleMonthChange(payload){
            setTitle(calendarRef.current?.getApi().getCurrentData().viewTitle)
            setCurrentStartDate(payload.start)
            setCurrentEndDate(payload.end)
            updateAllCalendars(payload.start, payload.end)
    }

    const printerFiendly = () => {
        navigate("/calendar/print", {state : {'events': events, 'display':calendarRef.current?.getApi().getCurrentData().viewSpec.type, 'title': calendars}})
    }

    const Sidebar = () =>{
        return(<Menu>
            {myEditableCalendars?.length > 0 ? <button type={"button"} className={"new-button"} onClick={()=>togglePopup()}>Add Event</button> : ''}
            <h4>My Calendars</h4>
            <div className="my-calendar-container">
                {calendars.map(calendar => (
                    <div className="calendar-select" style={{color: calendar.defaultColor, paddingBottom: 5}}><input type={"checkbox"}
                                                                                                   onChange={onCheckBoxChange}
                                                                                                   name={calendar.calendarName}
                                                                                                   value={calendar.calendarName}
                                                                                                   checked={showCalendars.includes(calendar.calendarName)}/> {calendar.calendarName}

                    </div>
                ))}
                <br/>
                <div className={'calendar-select'} onClick={()=> printerFiendly()}>Printer Friendly Version</div>
            </div>
        </Menu>)
    }

    if(message != null){
        return(<>
            <div className={"main-heading"}>
                <div className={"heading-display"}>
                    <div className={"title"}>Garden Spot Communities</div>
                </div>
            </div>
            {message}
        </>)
    }

    if(isLoading){
        return(<>
            <div className={"main-heading"}>
                <div className={"heading-display"}>
                    <div className={"title"}>Garden Spot Communities</div>
                </div>
            </div>
            <MySpinner/>
        </>)
    }
    const onDateClick = (info) =>{
        if(myEditableCalendars !== null && myEditableCalendars.length > 0){
            info.date.setMinutes(info.date.getMinutes() - info.date.getTimezoneOffset())
            setNewEvent({'start': info.date.toISOString().slice(0,16)})
            setPopUpOpen(!popUpOpen);
        }
    }

    const dayCheckBoxChange = event => {
        if(!byWeekday.includes(event.target.value)){
            setByWeekDay(byWeekday => [...byWeekday, event.target.value])
        }else{
            setByWeekDay(byWeekday.filter(item => item !== event.target.value))
        }
    }
    const onBlue = event =>{
        if(newEvent.end === undefined && !newEvent.allDay){
            try{
                const date = new Date(event.target.value)
                date.setMinutes(date.getMinutes() - date.getTimezoneOffset() +60)
                setNewEvent(prevState =>({
                    ...prevState,
                    end: date.toISOString().slice(0,16)
                }))
            }catch (e) {
                console.log(e)
            }

        }
    }

    const renderEventContent = (eventInfo) => {
        if (eventInfo.view.type === 'listWeek' && eventInfo.event.extendedProps.eventLocation !== undefined && eventInfo.event.extendedProps.eventLocation !== null) {
            return (
                <a href={'null'}>
                    <b>{eventInfo.event.title}</b> - {eventInfo.event.extendedProps.eventLocation}
                </a>
            )
        }
        return true;
    };

    const createIcal = async (event, calendarEvent) => {
        event.preventDefault()
        const formData = new FormData();
        console.log(moment(eventDataForIcal.start).toISOString(true))
        formData.set("startDateTime", moment(eventDataForIcal.start).toISOString(true))
        const resp = await postNoLogin(formData, calendarEvent.id)
        const url = URL.createObjectURL(resp);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = "calendar.ics";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
    }

    const eventDisplayToggle = () =>{
        if(newEvent.display && newEvent.display === 'block'){
            setNewEvent(prevEvent => ({
                ...prevEvent,
                display: 'auto' // toggle the value here.
            }));
        }else{
            setNewEvent(prevEvent => ({
                ...prevEvent,
                display: 'block' // toggle the value here.
            }));
        }
    }

    const filter = (loadedEvents) =>{
        const newEvents = [];
        let listWithCopyId = loadedEvents.filter(event => event.copyId && event.copyId !== 0);
        newEvents.push(...listWithCopyId)
        let listWithOutCopyId = loadedEvents.filter(event => !event.copyId || event.copyId === 0);
        listWithOutCopyId.filter((event) => {
            if (!newEvents.some(e => e.id === event.id || e.id === event.copyId || e.copyId === event.id)) {
                newEvents.push(event);
            }
        });
        return newEvents;
    }

    const copyToMyCalendar = (event, currentEvent) =>{
        event.preventDefault()
        const newEventObject = {...currentEvent};
        newEventObject.copyId = currentEvent.id;
        newEventObject.id = undefined;
        newEventObject.calendarInt = undefined;


        setNewEvent(newEventObject);

    }




    return (
        <div>
            <div className={"main-heading"}>
                <div className={"heading-display"}> {Sidebar()}
                    <div className={"title"}>{title}</div></div>
            </div>
            <br/>
            <div id="calendar">
            <FullCalendar
                schedulerLicenseKey={'0435152620-fcs-1708716914'}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin, listPlugin, adaptivePlugin]}
                ref={calendarRef}
                initialView={'dayGridMonth'}
                events={events}
                height={'auto'}
               eventContent={renderEventContent}
              //  eventDisplay={'auto'}
                eventTextColor={'#ffff'}
                eventClick={showEvent}
                dateClick={onDateClick}
                datesSet={handleMonthChange}
                eventTimeFormat={{minute: '2-digit', omitZeroMinute: false, hour: 'numeric'}}
                headerToolbar={{
                    left: "prev,next today",
                    center: "",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                }}
            ></FullCalendar></div>
            {popUpOpen && <PopUp
                content={<>
                {myEditableCalendars == null ? <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> :
                    canEditCalendar(newEvent.calendarInt) ?
                        <form name={"new-event"} onSubmit={submitForm}>
                            {canEditCalendar(newEvent.calendarInt) && newEvent.id ? <div style={{display: 'flex', cursor: 'pointer'}}
                                                                          onClick={() => deleteEvent(newEvent.id)}>
                                <FontAwesomeIcon icon={faTrash}/></div> : <></>}
                            <h3>{newEvent.id === undefined ? 'New Event' : 'Update Event'}</h3>
                            {canEditCalendar(newEvent.calendarInt) ? <div className={"input-group"}>
                                <Select
                                    placeholder={'Select Calendars'}
                                    closeMenuOnSelect={false}
                                    value={currentSelectedCalendars}
                                    components={animatedComponents}
                                    isMulti
                                    options={calendarSelect}
                                    onChange={onCalendarSelectChange}
                                    styles={{container: (provided) => ({...provided, width: '100%'})}}
                                />
                            </div> : <div>
                                <h5>{calendarDisplayString}</h5>
                            </div>}
                            <div style={{"display": "none"}} className="form-error" id={"calendar-error"}>Please select
                                a
                                calendar
                            </div>

                            <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Event Title
                             </span>
                                <input type={"text"}
                                       name={"title"}
                                       className={"input-group-field"}
                                       value={newEvent.title}
                                       onChange={handleChange}
                                />
                            </div>
                            <div style={{"display": "none"}} className="form-error" id={"title-error"}>Event title is
                                required
                            </div>
                            <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faMap}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Event Location
                             </span>
                                <input type={"text"}
                                       name={"eventLocation"}
                                       className={"input-group-field"}
                                       value={newEvent.eventLocation}
                                       onChange={handleChange}
                                />
                            </div>
                            <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon>&nbsp;&nbsp;
                                 All Day Event
                             </span>
                                <input type={"checkbox"}
                                       name={"allDay"}
                                       value={newEvent.allDay}
                                       checked={newEvent.allDay}
                                       onChange={allDayEventChange}
                                />
                            </div>

                            <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Display Event As block
                             </span>
                                <input type={"checkbox"}
                                       name={"display"}
                                       checked={newEvent?.display === 'block'}
                                       onChange={eventDisplayToggle}
                                />
                            </div>

                            <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon>&nbsp;&nbsp;
                                 This is a recurring event
                             </span>
                                <input type={"checkbox"}
                                       name={"recurrance"}
                                       value={'true'}
                                       checked={isRecurrance}
                                       onChange={recurranceChanges}
                                />
                            </div>
                            {isRecurrance ? <>


                                <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>&nbsp;&nbsp;
                                 {newEvent.allDay ? "Event Date" : "Event Start"}
                             </span>
                                    <input type={newEvent.allDay ? "date" : "datetime-local"}
                                           name={"dtstart"}
                                           className={"input-group-field"}
                                           value={rrule.dtstart}
                                           onChange={handleChangeRrule}
                                           onBlur={onBlue}
                                    />
                                </div>
                                <div style={{"display": "none"}} className="form-error" id={"rrule-start-error"}>Start
                                    Date
                                    required
                                </div>
                                {newEvent.allDay ? <></> : <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Event End
                             </span>
                                    <input type={"datetime-local"}
                                           name={"end"}
                                           className={"input-group-field"}
                                           value={newEvent.end}
                                           onChange={handleChange}
                                    />
                                </div>}
                                <div style={{"display": "none"}} className="form-error" id={"rrule-end-error"}>End Time
                                    is
                                    required
                                </div>
                                <div className={"input-group"}>
                            <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>&nbsp;&nbsp;
                                Select Recurrence Type
                             </span>
                                    <select onChange={handleChangeRrule} className={"input-group-field"} name={"freq"}>
                                        <option></option>
                                        <option value={'daily'} selected={rrule.freq === 'daily'}>Daily</option>
                                        <option value={'weekly'} selected={rrule.freq === 'weekly'}>Weekly</option>
                                        <option value={'monthly'} selected={rrule.freq === 'monthly'}>Monthly</option>
                                        <option value={'yearly'} selected={rrule.freq === 'yearly'}>Yearly</option>
                                    </select>
                                </div>

                                <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faListNumeric}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Interval
                             </span>
                                    <input type={'number'}
                                           name={"interval"}
                                           className={"input-group-field"}
                                           value={rrule.interval ?? 1}
                                           onChange={handleChangeRrule}
                                    />
                                </div>
                                <div style={{"display": "none"}} className="form-error"
                                     id={"rrule-interval-error"}>Interval
                                    must be 1 or greater
                                </div>
                                {rrule.freq === 'weekly' || rrule.freq === 'daily' ? <>
                                    <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faCalendarDays}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Days of the week
                             </span>
                                        {options.map(option => (
                                            <>{option.label}<input type={"checkbox"} value={option.value}
                                                                   onChange={dayCheckBoxChange}
                                                                   checked={byWeekday.includes(option.value)}/>&nbsp;&nbsp;</>))}
                                    </div>
                                    <div style={{"display": "none"}} className="form-error"
                                         id={"rrule-weekly-error"}>You
                                        must select what days of the week it should be on.
                                    </div>
                                </> : <></>}

                                <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faListNumeric}></FontAwesomeIcon>&nbsp;&nbsp;
                                 End after
                             </span>
                                    <input type={'number'}
                                           name={"count"}
                                           className={"input-group-field"}
                                           value={rrule.count}
                                           onChange={handleChangeRrule}
                                    />
                                    <span className={"input-group-label"}>
                                Occurrences
                                </span>
                                </div>

                                <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faListNumeric}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Or End On
                             </span>
                                    <input type={'date'}
                                           name={"count"}
                                           className={"input-group-field"}
                                           value={rrule.untill}
                                           onChange={handleChangeRrule}
                                    />
                                </div>

                            </> : <>

                                <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>&nbsp;&nbsp;
                                 {newEvent.allDay ? "Event Date" : "Event Start"}
                             </span>
                                    <input type={newEvent.allDay ? "date" : "datetime-local"}
                                           name={"start"}
                                           className={"input-group-field"}
                                           value={newEvent.start}
                                           onBlur={onBlue}
                                           onChange={handleChange}
                                    />
                                </div>
                                <div style={{"display": "none"}} className="form-error" id={"start-error"}>Event Start
                                    is
                                    required.
                                </div>

                                {newEvent.allDay ? '' : <div className={"input-group"}>
                             <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>&nbsp;&nbsp;
                                 Event End
                             </span>
                                    <input type={"datetime-local"}
                                           name={"end"}
                                           className={"input-group-field"}
                                           value={newEvent.end}
                                           onChange={handleChange}
                                    />
                                </div>}

                                <div style={{"display": "none"}} className="form-error" id={"end-error"}>Event End is
                                    required
                                </div>
                            </>}
                            {(newEvent.id === undefined || newEvent.id === null || isEditing) && !publicCalendar ?
                                <div className={"input-group"}>
                            <span className={"input-group-label"}>
                            <FontAwesomeIcon icon={faNoteSticky}></FontAwesomeIcon>&nbsp;&nbsp;
                                Event Notes
                            </span>
                                    <Editor
                                        apiKey={'kr6kh68cex59yd6e19ad2wqrex5ooo2jhiui3bidudqult41'}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={newEvent.description}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                            ],
                                            setup: function (editor) {
                                                editor.ui.registry.addButton('toggle', {
                                                    text: 'Toggle',
                                                    onAction: function () {
                                                        setIsEditing(false)
                                                    }
                                                })
                                            },
                                            toolbar: 'undo redo | blocks | link image|' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | toggle',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            automatic_uploads: true,
                                            paste_data_images: true,
                                            images_upload_handler: uploadImage,
                                        }}
                                    /><br/>
                                </div> : <div id={'html-container'}>{canEditCalendar(newEvent.calendarInt) ?
                                    <div id={'top-right'} onClick={() => setIsEditing(true)}><FontAwesomeIcon
                                        icon={faToggleOff}/></div> : <></>}
                                    <div style={{textAlign: 'left'}}
                                         dangerouslySetInnerHTML={{__html: newEvent.description}}></div>
                                </div>}


                            {canEditCalendar(newEvent.calendarInt) ?
                                <input type={"submit"} id={"submit"} className={"button expanded"}
                                       name={"submit-event"}
                                       value={newEvent.id === undefined ? "Add Event" : "Update Event"}/> : ''}

                        </form> : <>

                            <div>
                                <h5>{calendarDisplayString}</h5>
                            </div>
                            <div>{newEvent.title}</div>
                            <br/>
                            {newEvent.allDay ? '' :
                                newEvent.rrule != null ? <>
                                        <div><span
                                            style={{fontWeight: 'bold'}}>Start Time:</span> {formatDate(newEvent.rrule.dtstart)}
                                            <span style={{fontWeight: 'bold'}}>End Time: </span>{formatDate(newEvent.end)}
                                        </div>
                                        <br/></> :
                                    <>
                                        <div><span style={{fontWeight: 'bold'}}>Start Time:</span> {formatDate(newEvent.start)} <span style={{fontWeight: 'bold'}}>End Time: </span>{formatDate(newEvent.end)}
                                </div><br/></>}
                            {newEvent.eventLocation !== null ? <><div>Location: {newEvent.eventLocation}</div> <br/></>: ''}
                            <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: newEvent.description}}></div>
                            <div><a href={'#'} onClick={e => createIcal(e, newEvent)}>Add To Personal Calendar</a> </div>
                            {myEditableCalendars && myEditableCalendars.length > 0 && <div><a href={'#'} onClick={e => copyToMyCalendar(e, newEvent)}> Copy to Another Calendar</a></div>}
                        </>}
                </>}
                handleClose={togglePopup}
            ></PopUp>}
        </div>
    )



    function updateAllCalendars(startDate, endDate) {
        if(loading.current){
            return;
        }
        loading.current = true
        if (publicCalendar) {
            loadPublicCalendars(startDate, endDate).then((result) => {
                setAllCalendars(result);
                let loadedEvents = []
                let calendars = []
                let showCalendars = []
                for (let i = 0; i < result.length; i++) {
                    if(calendar === undefined){
                        for(const j in result[i].calendarEvents){
                            result[i].calendarEvents[j].backgroundColor = result[i].defaultColor
                        }
                        loadedEvents.push(...result[i].calendarEvents)
                        calendars.push(result[i])
                        showCalendars.push(result[i].calendarName)
                    }else{
                        if(result[i].calendarName.toLowerCase() === calendar.toLowerCase()){
                            for(const j in result[i].calendarEvents){
                                result[i].calendarEvents[j].backgroundColor = result[i].defaultColor
                            }
                            loadedEvents.push(...result[i].calendarEvents)
                            calendars.push(result[i])
                            showCalendars.push(result[i].calendarName)
                        }
                    }
                }
                setEvents(filter(loadedEvents))

                setCalendars(calendars)
                if(firstRun.current){
                    setShowCalendars(showCalendars)
                }
                resetCalenderEvents()
                loading.current = false;
                firstRun.current = false;
            })
        }else{
            loadCalendars(startDate, endDate, state.location).then((result) => {
                setAllCalendars(result);
                let loadedEvents = []
                let calendars = []
                let showCalendars = []
                for (let i = 0; i < result.length; i++) {
                    for(const j in result[i].calendarEvents){
                        result[i].calendarEvents[j].backgroundColor = result[i].defaultColor
                    }
                    loadedEvents.push(...result[i].calendarEvents)
                    calendars.push(result[i])
                    showCalendars.push(result[i].calendarName)
                }
                setEvents(filter(loadedEvents))

                setCalendars(calendars)
                if(firstRun.current){
                    setShowCalendars(showCalendars)
                }
                resetCalenderEvents()
                loading.current = false
                firstRun.current = false;
            })
        }

    }

    function startSocket(){
        authToken().then((res)=>{
            const client = new Client({
                brokerURL : "wss://socket.gsc.app/svc/ws",
                connectHeaders: {
                    Authorization: 'jwt '+ res
                },
                onConnect:()=>{
                    client.subscribe("/calendars/updates", message=>{
                        for(let i = 0; i < calendars.length; i++){
                            if(calendars[i].id.toString() === message.body.toString()){
                                updateAllCalendars(currentStartDate, currentEndDate)
                                return;
                            }
                        }
                    })
                },
            })
            setInterval(() => {
                client.publish({destination: '/app/ping', body: 'ping'})
            }, 90000)
            client.activate()
        })
    }





}

export default Calendar
