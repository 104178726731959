import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import listPlugin from "@fullcalendar/list";
import adaptivePlugin from "@fullcalendar/adaptive";
import FullCalendar from "@fullcalendar/react";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

function PrinterFriendly(){
    let { state } = useLocation();

    //for some reason it is not removing duplicate ids in the printer friendly version so we will remove manually

    const [eventDisplay, setEventDisplay] = useState([])

    useEffect(()=>{
        console.log(state.title)
        const ids = [];
        const events = [];
        for(const i in state.events){
            if(!ids.includes(state.events[i].id)){
                events.push(state.events[i])
                ids.push(state.events[i].id)
            }
        }
        setEventDisplay(events)

        //convertToImage()
    }, [state])

    const title = () =>{

        let title = ""
        for(const i in state.title){
            title += state.title[i].calendarName+", "
        }
        return title.substring(0, title.length-2)
    }

    const preventEvent = event =>{
        event.jsEvent.preventDefault()
    }


    return (<>
        <h3>{title()}</h3>
        <FullCalendar
        schedulerLicenseKey={'0435152620-fcs-1708716914'}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin, listPlugin, adaptivePlugin]}
        initialView={state.display}
        contentHeight={'auto'}
        eventClick={preventEvent}
        eventTextColor={"#ffff"}
        eventTimeFormat={{minute: '2-digit', omitZeroMinute: false, hour: 'numeric',}}
        fixedWeekCount={false}
        events={eventDisplay}
        headerToolbar={{
            left:   '',
            center: 'title',
            right:  ''
        }}
    ></FullCalendar></>)
}

export default PrinterFriendly
