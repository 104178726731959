import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./Login";
import Calendar from "./Calendar";
import PrinterFriendly from "./PrinterFriendly";

function App() {


  return (
    <div className="App">
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Login />}></Route>
      <Route exact path="/calendar" element={<Calendar />}></Route>
      <Route exact path="/calendar/public" element={<Calendar />}></Route>
      <Route exact path="/calendar/public/:calendar" element={<Calendar />}></Route>
      <Route exact path="/calendar/print" element={<PrinterFriendly/>}></Route>
    </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
