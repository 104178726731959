import Cookies from "universal-cookie";

const cookies = new Cookies();


const setLocationCookie = (location) =>{
    cookies.set("location", location, {
        path: "/"
    })
}

const clearCookie = () =>{
    cookies.remove("location")
}

const getCookie = () =>{
    return cookies.get("location");
}

export {
    setLocationCookie,
    clearCookie,
    getCookie
}
