import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import logo from "./logo.svg";
import {setAuth} from "./firebase";
import {setLocationCookie} from "./MyCookies";
function Login(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState(false)

   // const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
     //   if(user) navigate("/calendar")
        let authKey = searchParams.get("auth_key")
        let location = searchParams.get("location")
        if(location != null && location === "public"){
            //TODO: view only public calendar.
            return;
        }
        if(authKey == null || location == null){
            setError(true)
            return;
        }
        setLocationCookie(location)
        setAuth(location)
        navigate("/calendar", {state : {key: authKey, location : location}})

    }, [error]);

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    {error ? 'Something has gone wrong, we could not log you in' : 'Loading Calendars'}
                </p>
            </header>
        </div>
    );
}

export default Login
