import {authToken} from "./firebase";

const calenderApiUrl = "https://gsc.app/svc/"

//const calenderApiUrl = "http://localhost:8080/svc/"

const postLogin = async (authKey,  location) =>{
        const formData = new FormData();
        formData.set("auth_key", authKey)
        formData.set("location", location)

        let response = await fetch(calenderApiUrl+"calendar-auth", {
            method: 'POST',
            body: formData
        })

        return await response.json()

}

const postNoLogin = async (data, id) =>{
    const resp = await fetch(calenderApiUrl+"calendar-controller/ical/"+id, {
        method: 'POST',
        body: data
    })
    return await resp.blob();
}

const doGet = async (url) =>{
    let header = await authToken();
    let resp = await fetch(calenderApiUrl+url, {
        method: 'GET',
        headers: {'Authorization':'jwt '+header}
    })
    return await resp.json();
}

const doGetNoAuth = async (url) =>{
    let resp = await fetch(calenderApiUrl+url, {
        method: 'GET',
    })
    return await resp.json();
}

const doPostBody = async (data, url) =>{
    let header = await authToken();
    let response = await fetch(calenderApiUrl+url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({"Authorization" : "jwt "+header, "Content-Type": "application/json"})
    })
    return await response.json()
}

const loadPublicCalendars = async (startDate, endDate) =>{
    const response = await fetch(calenderApiUrl+"calendar-controller/public-calendars?date="+startDate.toISOString().split('T')[0]+"&endDate="+endDate.toISOString().split('T')[0], {
        method : 'GET'
    })
    return await response.json()
}

const loadCalendars = async(startDate, endDate, location) => {
    let header = await authToken();
    let resp = await fetch(calenderApiUrl+"calendar-controller/load-calendars/"+location+"?date="+startDate.toISOString().split('T')[0]+"&endDate="+endDate.toISOString().split('T')[0], {
        method: 'GET',
        headers: {'Authorization':'jwt '+header}
    })
    return await resp.json()

}

const uploadImage = (blobInfo, progress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    authToken().then(token =>{
        xhr.open('POST', calenderApiUrl+"calendar-controller/image-upload")
        xhr.setRequestHeader("Authorization", "jwt "+token)
        xhr.upload.onprogress = (e) => {
            progress(e.loaded / e.total * 100);
        };

        xhr.onload = () => {
            if (xhr.status === 403) {
                reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
                reject('HTTP Error: ' + xhr.status);
                return;
            }

            const json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != 'string') {
                reject('Invalid JSON: ' + xhr.responseText);
                return;
            }

            resolve(json.location);
        };

        xhr.onerror = () => {
            reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
        };

        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        xhr.send(formData);
    })

});

export {
    postLogin,
    loadCalendars,
    doGet,
    doPostBody,
    loadPublicCalendars,
    doGetNoAuth,
    calenderApiUrl,
    uploadImage,
    postNoLogin
}
